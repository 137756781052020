import React from "react";
import { graphql } from "gatsby";
import PortableText from "react-portable-text";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import FeaturedList from "../components/common/featuredlist";
import Review from "../components/artist/Review";
import RegenerativePledge from "../components/common/regenerativePledge";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import Seo from "../components/seo";
import Button from "../components/common/buttons/Button";

const Artist = ({ data: { sanityArtistPage, sanitySitesettings } }) => {
  let seo;
  let hero;
  let breadcrumb;
  let features;
  let review;
  let regenerative;

  sanityArtistPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "featureList") {
      features = element;
    } else if (element._type === "reviewSection") {
      review = element;
    } else if (element._type === "regenerative") {
      regenerative = element;
    }
  });

  return (
    <Layout>
      <Seo data={seo} />
      <Hero
        heroSection={hero}
        h2Size="text-2xl sm:text-[40px] leading-[48px]"
        h2P="pb-7"
        h1Size="text-[48px] leading-[77px]"
      />
      <Logo logo={sanitySitesettings?.logo} />

      {features && <FeaturedList data={features} />}
      {review && <Review data={review} />}

      {sanityArtistPage?._rawDescription && (
        <div className="max-w-[825px] mx-auto p-5 show">
          <PortableText
            content={sanityArtistPage?._rawDescription}
            className="font-semibold text-[#323A44] block-content max-w-none"
          />
          <hr />
        </div>
      )}
      <div className="max-w-[820px] my-3 px-5 mx-auto">
        {sanityArtistPage?.ctaButton.title && (
          <Button
            btnText={sanityArtistPage?.ctaButton?.title}
            btnLink={sanityArtistPage?.ctaButton?.link}
            btnType={sanityArtistPage?.ctaButton?.variant}
            linkType={sanityArtistPage?.ctaButton?.type}
            formId={sanityArtistPage?.ctaButton?.formId}
          />
        )}
      </div>

      {sanityArtistPage?._rawNote && (
        <div className="max-w-[825px] mx-auto p-5 show mb-2">
          <PortableText
            content={sanityArtistPage?._rawNote}
            className="font-semibold text-[#323A44] block-content max-w-none"
          />
        </div>
      )}

      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {regenerative && (
        <div>
          <RegenerativePledge data={regenerative} />
          <Logo logo={sanitySitesettings?.logo} />
        </div>
      )}
    </Layout>
  );
};

export default Artist;

export const query = graphql`
  {
    sanityArtistPage {
      pageBuilder {
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          ctaButton {
            link
            title
            type
            variant
            formId
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityFeatureList {
          _type
          featureItems {
            icon
            title
          }
        }
        ... on SanityReviewSection {
          _type
          comment
          subHeading
          subHeading2
          heading
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
          }
        }
        ... on SanityRegenerative {
          _type
          heading
          _rawContent
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
        }
      }
      _rawDescription
      _rawNote
      ctaButton {
        link
        title
        formId
        type
        variant
        formId
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
