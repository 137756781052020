import React from "react";
import Button from "../common/buttons/Button";
import PortableText from "react-portable-text";

const Review = ({ data }) => {
  return (
    <div
      className="relative block overflow-hidden box-border bg-cover bg-repeat-x show"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(102, 102, 102, 0.90),rgba(214, 214, 214, 0.90)),url(${data?.sectionBg?.asset?.url})`,
      }}
    >
      <div className="relative flex flex-col flex-wrap justify-center min-h-[90vh]">
        <div className="relative max-w-[1140px] mx-auto">
          {/* Section which will be used other components also */}
          <div className="relative py-5 px-[10px] min-h-[80vh] flex flex-col flex-wrap justify-center box-border">
            {/* Title */}
            <div className="w-full pb-5">
              <h2 className="text-2xl sm:text-6xl text-white text-center  mb-3 font-bold tracking-[2px]">
                {data?.heading}
              </h2>
            </div>
            {/* Subtitle 1 */}
            <div className="w-full pb-5">
              <p className="text-xl sm:text-3xl text-white text-center mb-3 font-bold tracking-[2px]">
                {data?.subHeading}
              </p>
            </div>
            {/* Subtitle 2 */}
            <div className="w-full pb-5">
              <p className="text-xl sm:text-3xl text-white text-center mb-3 font-bold tracking-[2px]">
                {data?.subHeading2}
              </p>
            </div>
            {/* Paragraph */}
            <div className="w-3/4 mx-auto">
              <p className="text-base sm:text-lg text-left text-white font-bold">
                "{data?.comment}"
              </p>
            </div>
            {/* Reviewer details */}
            <div className="w-3/4 mx-auto">
              {data?._rawAuthor && (
                <PortableText
                  content={data._rawAuthor}
                  className="block-content text-right text-white my-6 text-lg font-semibold max-w-none"
                />
              )}
            </div>
            <div className="w-[95%] mx-auto">
              <div className="flex flex-row flex-wrap max-w-full align-top items-stretch justify-around">
                {/* Button 1 */}
                <div
                  className="w-full sm:w-custom-sm lg:w-custom-lg mb-[10px]
                flex flex-col flex-wrap justify-start relative"
                >
                  {data?.ctaButton1 && (
                    <div className="relative block pt-5 pb-0 md:pb-10 m-[1px]">
                      <Button
                        btnText={data?.ctaButton1?.title}
                        btnLink={data?.ctaButton1?.link}
                        btnType={data?.ctaButton1?.variant}
                        linkType={data?.ctaButton1?.type}
                        formId={data?.ctaButton1?.formId}
                      />
                    </div>
                  )}
                </div>
                {/* Button 2 */}
                <div
                  className="w-full sm:w-custom-sm lg:w-custom-lg mb-[10px]
                flex flex-col flex-wrap justify-start relative"
                >
                  {data?.ctaButton2 && (
                    <div className="relative block pt-5 pb-0 md:pb-10 m-[1px]">
                      <Button
                        btnText={data?.ctaButton2?.title}
                        btnLink={data?.ctaButton2?.link}
                        btnType={data?.ctaButton2?.variant}
                        linkType={data?.ctaButton2?.type}
                        formId={data?.ctaButton2?.formId}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
